<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-items-center justify-start">
        <v-icon>mdi-card-account-details</v-icon>
        <h4 class="ml-3 mb-0">บันทึกเคส</h4>
      </div>

      <div class="d-flex flex-row align-end justify-start mb-1">
        <v-icon>mdi-account</v-icon>
        <span class="ml-3">ประวัติการรักษา</span>
      </div>
      <v-textarea
        outlined
        class="mt-3"
        name="input-7-4"
        label=""
        :value="inputMedicalHistory"
        :rules="inputRules"
        single-line
        hide-details
      ></v-textarea>

      <div class="d-flex flex-row align-end justify-start mt-3 mb-1">
        <v-icon>mdi-pencil</v-icon>
        <span class="ml-3">ตรวจร่างกาย</span>
      </div>
      <v-img
        lazy-src="../../../assets/images/canvas/canvas_male.jpg"
        max-height="450"
        max-width="350"
        contain
        src="../../../assets/images/canvas/canvas_male.jpg"
      ></v-img>

      <div class="d-flex flex-row align-end justify-start mt-3 mb-1">
        <v-icon>mdi-doctor</v-icon>
        <span class="ml-3">แพทย์และลายเซ็นต์</span>
      </div>
      <v-select
        :items="itemsDoctor"
        v-model="selectPhysician"
        item-text="name"
        item-value="value"
        label="แพทย์*"
        no-data-text="ยังไม่มีข้อมูลแพทย์์"
        :rules="selectRules"
        required
      ></v-select>

      <div class="d-flex flex-row align-end justify-start mt-3 mb-1">
        <v-icon>mdi-pill</v-icon>
        <span class="ml-3">ยา</span>
      </div>
      <v-select
        :items="itemsPill"
        v-model="selectPill"
        item-text="name"
        item-value="value"
        label="ชื่อยา*"
        no-data-text="ยังไม่มีข้อมูลชื่อยา"
        :rules="selectRules"
        required
      ></v-select>
      <v-text-field
        class="mt-3"
        v-model="inputHowtousepill"
        label="วิธีการใช้ยา"
        outlined
        single-line
        hide-details
      ></v-text-field>
      <v-text-field
        class="mt-3"
        v-model="inputCountpill"
        label="จำนวน"
        suffix="เม็ด"
        outlined
        single-line
        hide-details
      ></v-text-field>

      <div class="d-flex flex-row align-end justify-start mt-3 mb-1">
        <v-icon>mdi-cash</v-icon>
        <span class="ml-3">ค่ามือ</span>
      </div>
      <v-text-field
        class="mt-3"
        v-model="inputWages"
        label="ค่ามือ"
        suffix="บาท"
        outlined
        single-line
        hide-details
      ></v-text-field>

      <div class="d-flex flex-row align-end justify-start mt-3 mb-1">
        <v-icon>mdi-medical-bag</v-icon>
        <span class="ml-3">ห้องปฏิบัติการ</span>
      </div>
      <v-select
        :items="itemsRoom"
        v-model="selectRoom"
        item-text="name"
        item-value="value"
        label="ห้องปฏิบัติการ*"
        no-data-text="ยังไม่มีข้อมูลห้องปฏิบัติการ"
        :rules="selectRules"
        required
      ></v-select>

      <v-card-actions class="pt-0">
        <v-spacer/>
        <v-btn
          text
          color="error"
          @click="cancelSaveCase"
        >
          ยกเลิก
        </v-btn>

        <v-btn
          color="success"
          @click="saveCase"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loaddata: false,
      customerData: {},
      itemsDoctor: [],
      itemsPill: [],
      itemsRoom: [],
      inputRules: [
        v => !!v || 'กรุณากรอกข้อมูล'
      ],
      selectRules: [
        v => !!v || 'กรุณาเลือกข้อมูล'
      ],
      inputMedicalHistory: '',
      selectPhysician: '',
      selectPill: '',
      inputHowtousepill: '',
      inputCountpill: 0,
      inputWages: 0,
      selectRoom: ''
    }
  },
  created () {
    const self = this
    if (self.$route.params.customerItems) self.customerData = self.$route.params.customerItems
    self.initData()
  },
  methods: {
    initData () {
      const self = this
      self.loaddata = true
      // console.log(self.customerData)
      setTimeout(() => {
        self.loaddata = false
      }, 500)
    },
    cancelSaveCase () {
      const self = this
      swal('คำเตือน', 'ยังไม่ได้บันทึกเคส', 'warning', {
        button: false,
        timer: 2000
      }).then(() => {
        self.$router.go(-1)
      })
    },
    saveCase () {
      const self = this
      swal('สำเร็จ', 'บันทึกเคสสำเร็จ', 'success', {
        button: false,
        timer: 2000
      }).then(() => {
        self.$router.go(-1)
      })
    }
  }
}
</script>
